import axios from "axios"
import Swal from "sweetalert2";

const INDUSTRIES_URL = process.env.REACT_APP_URI_INDUSTRY

const {
	REACT_APP_URI_VALIDATE_SITUACION_FISCAL: SITUACION_FISCAL_COMPLIANCE_URL,
	REACT_APP_URI_VALIDATE_OPINION_CUMPLIMIENTO: OPINION_CUMPLIMIENTO_URL,
	REACT_APP_URI_VALIDATE_REPSE: REPSE_URL,
	REACT_APP_URI_VALIDATE_OPINION_IMSS: OPINION_IMSS_URL,
	REACT_APP_URI_VALIDATE_OPINION_INFONAVIT: OPINION_INFONAVIT_URL,
	REACT_APP_URI_VALIDATE_STAFF_DOCUMENT: SUA,
} = process.env

// DOCUMENT ID OF THE DOCUMENTS THAT NEED VALIDATION OR DO SOMETHING AUTOMATIC
export const legalDocumentIDs = {
	constanciaSituacionFiscalFisica: "6564fc579081ec1ad85d7425",
	opicionPositivaSatFisica: "6564fd669081ec1ad85d742a",
	repseFisica: "663bb05df13a85d0d6b3a1d6",
	opinionPositivaImssFisica: "663bb05df13a85d0d6b3a1d3",
	opinionPositivaInfonavitFisica: "663bb05df13a85d0d6b3a1d2",
	suaFisica: "66c8eea61528ec0c0476340e",

	constanciaSituacionFiscalMoral: "6564fd9b9081ec1ad85d7435",
	opicionPositivaSatMoral: "6564fdac9081ec1ad85d7438",
	repseMoral: "6564fd939081ec1ad85d7433",
	opinionPositivaImssMoral: "6564fdb09081ec1ad85d7439",
	opinionPositivaInfonavitMoral: "6564fdb59081ec1ad85d743a",
	suaMoral: "66c8eea61528ec0c0476340f",
	actaConstitutivaOriginalMoral: "6564fd6c9081ec1ad85d742b",
	idRepresentanteLegalMoral: "6564fd769081ec1ad85d742d"
}

// VARIABLE TO VALIDATE WICH DOCUMENTS NEED TO REMOVE IF THE SUPPLIER DOES NOT HAVE REPSE
export const removeDocumentNoRepse = [
	legalDocumentIDs.repseFisica,
	legalDocumentIDs.opinionPositivaImssFisica,
	legalDocumentIDs.opinionPositivaInfonavitFisica,
	legalDocumentIDs.suaFisica,

	legalDocumentIDs.repseMoral,
	legalDocumentIDs.opinionPositivaImssMoral,
	legalDocumentIDs.opinionPositivaInfonavitMoral,
	legalDocumentIDs.suaMoral,
]

// GET THE URL FOR THE DOCUMENT VALIDATION
const getDocumentUrl = async (documentID) => {
	switch (documentID) {
		case legalDocumentIDs.constanciaSituacionFiscalFisica:
		case legalDocumentIDs.constanciaSituacionFiscalMoral:
			return SITUACION_FISCAL_COMPLIANCE_URL
		case legalDocumentIDs.opicionPositivaSatFisica:
		case legalDocumentIDs.opicionPositivaSatMoral:
			return OPINION_CUMPLIMIENTO_URL
		case legalDocumentIDs.repseFisica:
		case legalDocumentIDs.repseMoral:
			return REPSE_URL
		case legalDocumentIDs.opinionPositivaImssFisica:
		case legalDocumentIDs.opinionPositivaImssMoral:
			return OPINION_IMSS_URL
		case legalDocumentIDs.opinionPositivaInfonavitFisica:
		case legalDocumentIDs.opinionPositivaInfonavitMoral:
			return OPINION_INFONAVIT_URL
		case legalDocumentIDs.suaFisica:
		case legalDocumentIDs.suaMoral:
			return SUA
		default:
			return ""
	}
}

// FUNCTION TO VALIDATE THE DOCUMENT AND RETURN THE DATA
export const validateDocument = async (document, supplierId, companyName, supplierRfc, typeOfPerson) => {
	// console.log(document);
	const url = await getDocumentUrl(document.documentID)
	// console.log(url);

	if (!url) {
		return {
			errorMessage: "We could not validate your document, please try again later \n\n CODE: {URL}"
		}
	}

	const formData = new FormData()
	formData.append("rfc", "temporal")
	formData.append("typeDocument", "SUA");
	formData.append("supplierId", supplierId);
	formData.append("typeOfPerson", typeOfPerson);
	formData.append("companyName", companyName)
	formData.append("rfcSupplier", supplierRfc)
	formData.append("document", document.document, `${document.yearCreatedAt}-${document.monthCreatedAt}-${document.document.name}`)
	formData.append("docDateCreation", JSON.stringify({
		year: document.yearCreatedAt,
		month: document.monthCreatedAt,
		day: document.dayCreatedAt,
	}))

	const { data } = await axios.post(url, formData)

	// console.log(data);

	if (data.error) {
		return {
			errorMessage: data.message,
			errorMessagePercent: data.messagePercent
		}
	}

	return data
}

// Get all industries
export async function getAllIndustries() {
	try {
		const { data } = await axios.get(INDUSTRIES_URL)

		return data
	} catch (error) {
		console.log("Error al obtener las industrias")
	}
}

// Convert date to a readable format
export function convertDate(date) {
	if (!date) return "N/A"

	const formattedDate = new Date(date).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }).replace(/,/g, '')
	return formattedDate;
}