export const htmlOrderTemplate = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Purchasing PDF</title>
    <link rel="stylesheet" href="pdfPurchasing.css">
        <style>
    :root {
	--gradient: linear-gradient(180deg, #009AFF 19.79%, #8CA5FF 100%);
	--darkBlue: rgba(4, 26, 57, 0.8);
	--blue: #00A3FF;
	--lightBlue: rgba(0, 163, 255, 0.1);
	--white: #FFFFFF;
	--ligthPurple: #8CA5FF;
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: "DM sans", sans-serif !important;
	color: #000000 !important;
}

h1,
h2,
h3,
h4,
h5,
span {
	display: block;
	margin: 0;
}

h1 {
	font-size: 24px;
	font-weight: 700;
}

h2 {
	font-size: 16px;
	font-weight: 700;
}

h3 {
	font-size: 14px;
	font-weight: 700;
}

h4 {
	font-size: 12px;
	font-weight: 700;
}

h5 {
	font-size: 15px;
	font-weight: 700;
}

span {
	font-size: 14px
}

.header {
	padding: 20px 20px;
	background: linear-gradient(180deg, #009AFF 19.79%, #8CA5FF 100%);
	display: flex;
	align-items: center;
	height: 100px;
	padding-bottom: 20px;
}

.header h1 {
	color: white;
}

.detailsInformationContainer {
	margin-bottom: 30px;
}

.detailsInformationContainer>.infoTitle {
	margin: 10px 20px;
	padding: 0 0 0 10px;
    color: #47A0FF;
	border-bottom: 2px solid #8CA5FF;
}

.detailsInformationContainer .infoContainer > * {
	display: table;
    height: 100%;
    width: 100%;
    padding: 5px 30px 15px 30px;
    margin-bottom: 10px;
}

.detailsInformationContainer .infoContainer .subInfoContainer {
	display: table-cell;
    vertical-align: top;
	width: 330px;
}

.detailsInformationContainer .infoContainer .subInfoContainer .infoTitle {
	color: #009AFF;
	margin-bottom: 20px;
	flex: 1 0 50%;
	font-size: 10px;
	font-weight: 700;
}

.detailsInformationContainer .infoContainer:last-of-type {
	margin-bottom: 0;
}

.detailsInformationContainer .infoContainer .infoValue {
	margin-bottom: 5px;
	flex: 1 0 50%;
	font-size: 14px;
}

.detailsInformationContainer .infoContainer .infoTitle {
	color: linear-gradient(180deg, #009AFF 19.79%, #8CA5FF 100%);
	margin-bottom: 20px;
	flex: 1 0 50%;
}

.detailsInformationContainer .infoContainer .infoTitle:nth-last-of-type(1),
.detailsInformationContainer .infoContainer .infoTitle:nth-last-of-type(2) {
	margin-bottom: 0;
}

.detailsInformationContainer .infoContainer .infoValue:nth-of-type(1) {
	grid-area: A;
}

.detailsInformationContainer .infoContainer .infoValue:nth-of-type(2) {
	grid-area: B;
}

.detailsInformationContainer .infoContainer .infoValue:nth-of-type(3) {
	grid-area: E;
}

.detailsInformationContainer .infoContainer .infoValue:nth-of-type(4) {
	grid-area: F;
}

.detailsInformationContainer .infoContainer .infoTitle:nth-of-type(1) {
	grid-area: C;
}

.detailsInformationContainer .infoContainer .infoTitle:nth-of-type(2) {
	grid-area: D;
}

.detailsInformationContainer .infoContainer .infoTitle:nth-of-type(3) {
	grid-area: G;
}

.detailsInformationContainer .infoContainer .infoTitle:nth-of-type(4) {
	grid-area: H;
}

.productsTable {
	width: 100%;
}

.tableHeader {
	height: 30px;
	position: relative;
}

.tableHeader, .tableRow, .tableTotalPrice {
    display: table;
    width: 100%;
    table-layout: fixed; /* Asegura que las celdas tengan el mismo ancho */
}

.headerTitle, .rowValue, .priceTitle, .priceValue {
    display: table-cell;
    padding: 10px;
    text-align: center;
    vertical-align: middle;
	font-size: 14px;
}

.headerTitle {
    color: #47A0FF;
    font-weight: bold;
	font-size: 12px;
}

.productsTable .tableHeader .headerTitleProduct {
	display: flex;
	justify-content: center;
	align-items: center;
    height: 25px;
	background: #009AFF;
	position: absolute;
	bottom: 10px;
	width: 20%;
}

.headerTitleItemProduct {
	display: flex;
	margin-bottom: 0px;
	font-weight: bold;
	font-size: 12px;
	color: white;
	border-bottom: 0px solid #8CA5FF;
}

.headerTitleItem {
    border-bottom: 2px solid #8CA5FF;
	padding-bottom: 0px;
}

.productsTable .tableBody .tableRow {
    border-bottom: 1px solid rgba(4, 26, 57, 0.05);
}

.productsTable .tableBody .tableRow:last-of-type {
	border-bottom: none;
}

.productsTable .tableBody .tableRow .rowValue {
    color: rgba(4, 26, 57, 0.7);
    font-size: 12px;
    padding-bottom: 15px;
}

.productsTable .tableBody .tableRow .rowValue:nth-of-type(2) {
    background: rgba(0, 163, 255, 0.1);
    color: #47A0FF;
    font-size: 12px;
    padding-bottom: 15px;
}

.productsTable .tableTotalPrice {
    display: flex;
	height: 50px;
	width: 97%;
	justify-content: space-between;
    border-top: 2px solid rgba(0, 154, 255, 1);
    border-bottom: 2px solid rgba(140, 165, 255, 1);
	margin: 0 0 0 10px;
}

.productsTable .tableTotalPrice .priceTitle {
    color: rgba(0, 154, 255, 1);
    font-weight: bold;
    text-align: left;
	padding: 12px 0 0 25px;
}

.productsTable .tableTotalPrice .priceValue {
	display: flex;
	justify-content: center;
	align-items: center;
	color: rgba(0, 154, 255, 1);
    font-weight: bold;
	padding-bottom: 12px;
	padding-right: 30px;
}
    </style>
</head>
<body>
    <section class="header">
        <h1>Purchase order #{{folio}}</h1>
    </section>

    <section class="detailsInformationContainer">
        <h2 class="infoTitle">SUPPLIER</h2>
        <div class="infoContainer">
			<div class="subInfoContainer">
				<span class="infoValue">{{supplierRFC}}</span>
				<h5 class="infoTitle">RFC</h5>
			</div>
			<div class="subInfoContainer">
				<span class="infoValue">{{supplierName}}</span>
				<h5 class="infoTitle">NAME</h5>
			</div>
        </div>

        <h2 class="infoTitle">CLIENT</h2>
        <div class="infoContainer">
			<div class="subInfoContainer">
				<span class="infoValue">{{clientName}}</span>
				<h5 class="infoTitle">NAME</h5>
			</div>
			<div class="subInfoContainer">
				<span class="infoValue">{{clientAddress}}</span>
				<h5 class="infoTitle">ADDRESS</h5>
			</div>
        </div>
    </section>

    <section class="productsTable">
        <div class="tableHeader">
            <div class="headerTitle">Barcode</div>
            <div class="headerTitle">Product</div>
            <div class="headerTitle">Bundle qty.</div>
            <div class="headerTitle">Total packages</div>
            <div class="headerTitle">Total units</div>
            <div class="headerTitle">Bundle price</div>
            <div class="headerTitle">Total</div>
        </div>
        <div class="tableBody">
            <!-- products -->
        </div>
        <div class="tableTotalPrice">
            <h2 class="priceTitle">Total order price:</h2>
            <h2 class="priceValue">\${{totalOrderPrice}}</h2>
        </div>
    </section>
</body>
</html>`;
